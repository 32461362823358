<template>
  <b-row
    align-h="center"
    class="mt-5"
  >
    <b-col md="8">
      <b-card :header="texts.header">
        <validation-observer #default="{handleSubmit}">
          <b-form @submit.prevent>
            <validation-provider
              #default="validationContext"
              name="New Password"
              rules="required|password"
              vid="confirmed"
            >
              <b-form-group
                :label="texts.password"
                label-for="password"
              >
                <b-input-group>
                  <b-form-input
                    id="password"
                    v-model="newPassword.password"
                    :placeholder="texts.password"
                    :type="passwordFieldType"
                    :state="getValidationState(validationContext)"
                  />

                  <template #append>
                    <b-input-group-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        size="15"
                        class="form-control-eye"
                        @click="togglePasswordVisibility()"
                      />
                    </b-input-group-text>
                  </template>

                </b-input-group>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>

              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Confirm Password"
              rules="required|confirmed:confirmed"
            >
              <b-form-group
                label="Confirm Password"
                label-for="password_confirmation"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model="newPassword.password_confirmation"
                  placeholder="Confirm Password"
                  :type="passwordFieldType"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <submit-button
              :handle-submit="handleSubmit"
              :submit="resetPassword"
            />

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="ml-1"
              :to="{name:'login'}"
            >
              Back
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'

export default {
  name: 'ResetPassword',
  components: {
    SubmitButton,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      passwordFieldType: 'password',
      newPassword: {
        email: this.$route.query.email,
        token: this.$route.query.token,
      },
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()

    const {
      getValidationState,
    } = formValidation()

    return {
      successfulOperationAlert, getValidationState,
    }
  },
  computed: {
    texts() {
      const resetPassword = { header: 'Reset Password', password: 'New Password' }
      const newPassword = { header: 'Set a Password', password: 'Password' }
      return this.$route.query.reset_pass === 'true' ? resetPassword : newPassword
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    resetPassword() {
      const resetPasswordMessage = 'Password is reset successfully'
      const setPasswordMessage = 'Password is set successfully'
      const message = this.$route.query.reset_pass === 'true' ? resetPasswordMessage : setPasswordMessage

      return this.$portalUsers.post('v1/password/reset', this.newPassword).then(() => {
        this.successfulOperationAlert(message)
        this.$router.push({ name: 'login' })
      })
    },
  },
}
</script>
<style lang="scss">

</style>
